import React from 'react';
import { bindings, hook } from '@vl/redata';
import _ from 'lodash';

const bindData = bindings({
  component: {
    rules: [
      [
        'data',
        {
          data: {
            menuData: hook((ctx) => {
              return _.compact([
                {
                  href: ctx.apply('routeStore.toUrl', 'toolAccountDetail'),
                  id: 'home',
                  text: ctx.apply('i18n.t', 'ZoomToolMenu.Home'),
                  icon: 'home',
                  show: true,
                },
                !!ctx.apply('routeStore.toUrl', 'toolAccountCourseTemplates') && {
                  href: ctx.apply('routeStore.toUrl', 'toolAccountCourseTemplates'),
                  id: 'course-templates',
                  text: ctx.apply('i18n.t', 'ZoomToolMenu.CourseTemplates'),
                  icon: 'course-templates',
                  show: true,
                },
                !!ctx.apply('routeStore.toUrl', 'toolAccountCourses') && {
                  href: ctx.apply('routeStore.toUrl', 'toolAccountCourses'),
                  id: 'courses',
                  text: ctx.apply('i18n.t', 'ZoomToolMenu.Courses'),
                  icon: 'menuBook',
                  show: true,
                },
                !!ctx.apply('routeStore.toUrl', 'toolAccountProgramTemplates') && {
                  href: ctx.apply('routeStore.toUrl', 'toolAccountProgramTemplates'),
                  id: 'program-templates',
                  text: ctx.apply('i18n.t', 'ZoomToolMenu.ProgramsTemplates'),
                  icon: 'programs-templates',
                  show: true,
                },
                !!ctx.apply('routeStore.toUrl', 'toolAccountPrograms') && {
                  href: ctx.apply('routeStore.toUrl', 'toolAccountPrograms'),
                  id: 'programs',
                  text: ctx.apply('i18n.t', 'ZoomToolMenu.Programs'),
                  icon: 'programs',
                  show: true,
                },
                // {
                //   href: ctx.apply('routeStore.toUrl', 'toolAccountMember'),
                //   id: 'members',
                //   text: ctx.apply('i18n.t', 'ZoomToolMenu.Admins'),
                //   icon: 'admins',
                //   show: true,
                // },
                !!ctx.apply('routeStore.toUrl', 'toolAccountTeachers') && {
                  href: ctx.apply('routeStore.toUrl', 'toolAccountTeachers'),
                  id: 'teachers',
                  text: ctx.apply('i18n.t', 'ZoomToolMenu.Teachers'),
                  icon: 'teachers',
                  show: true,
                },
                !!ctx.apply('routeStore.toUrl', 'toolAccountStudents') && {
                  href: ctx.apply('routeStore.toUrl', 'toolAccountStudents'),
                  id: 'students',
                  text: ctx.apply('i18n.t', 'ZoomToolMenu.Students'),
                  icon: 'students',
                  show: true,
                },
                !!ctx.apply('routeStore.toUrl', 'toolAccountCalendar') && {
                  href: ctx.apply('routeStore.toUrl', 'toolAccountCalendar'),
                  id: 'calendar',
                  text: ctx.apply('i18n.t', 'ZoomToolMenu.Calendar'),
                  icon: 'calendar',
                  show: true,
                },
                !!ctx.apply('routeStore.toUrl', 'toolAccountNotification') && {
                  href: ctx.apply('routeStore.toUrl', 'toolAccountNotification'),
                  id: 'notifications',
                  text: ctx.apply('i18n.t', 'ZoomToolMenu.Notifications'),
                  icon: 'notification',
                  badge: true,
                  count: ctx.get('notificationCount'),
                  show: true,
                },
              ]);
            }),
          },
        },
      ],
    ],
  },
});

export default bindData;
