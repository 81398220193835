import React from 'react';
import useRoute from '@vl/hooks/useGbRouteDe';
import { ctx } from '@vl/redata';
import DIV from '@vl/redata/DIV.macro';
import { Image } from '@uz/unitz-components-web/Image';
import displayName from '@vl/redata/displayName.macro';
import _ from 'lodash';
import cx from 'classnames';
import useDimensions from 'react-cool-dimensions';
import styled from 'styled-components';
import SectionWrapper from '@uz/unitz-ctf-theme/components/SectionWrapper';
import LangSwitch from '@uz/unitz-ctf-theme/layouts/LangSwitchUS';
import gstyles from '@vl/gstyles';
import { Drawer } from 'antd';
import AccountListSideBar from '@uz/unitz-components-web/AccountListSideBar';
import { renderPropsComposer } from '@vl/mod-utils/PropsComposer';
import { getLocaleConfig, getCurrentLocaleConfig } from '@uz/mod-translations/utils-biz';
import NavbarNotification from '@uz/unitz-tool-components/NavbarNotification';
import NavbarMenuProfile from '@uz/unitz-tool-components/NavbarMenuProfile';
import NavbarAuthSection from '@uz/unitz-tool-components/NavbarAuthSection';
// const hasScrollY = () => {
//   // eslint-disable-next-line
//   const [hasScroll, $hasScroll] = React.useState(false);
//   // eslint-disable-next-line
//   React.useEffect(() => {
//     if (typeof window !== 'undefined') {
//       window.addEventListener('scroll', () => {
//         let scroll = null;
//         if (window.scrollY === 0) {
//           scroll = false;
//         } else {
//           scroll = true;
//         }
//         $hasScroll(scroll);
//       });
//     }
//   }, []);
//   return hasScroll;
// };

// const StickyNav = styled(({ children, className }) => {
//   const hasScroll = hasScrollY();
//   const { observe, height } = useDimensions({
//     onResize: _.throttle(() => {}, 1000),
//   });
//   const ref = React.useRef({});
//   const placeHolderHeight = React.useMemo(() => {
//     if (height > 0) {
//       ref.current.height = height;
//     }
//     return ref.current.height;
//   }, [height]);

//   return (
//     <div className={className}>
//       <div className="header-Placeholder" style={{ height: placeHolderHeight }} />
//       <header className={cx(`header-sticky ${hasScroll ? 'sticky' : ''}`)} ref={observe}>
//         {children}
//       </header>
//     </div>
//   );
// })`
//   > .sticky {
//     position: fixed;
//   }
// `;

const DesktopNavbar = () => {
  const route = useRoute();
  const currentLocale = getCurrentLocaleConfig(route);
  const [visible, setVisible] = React.useState(false);
  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  const lang =
    _.get(currentLocale, 'config.name', '') === 'default' ? '/' : `/${_.get(currentLocale, 'config.langSlug', '')}`;
  return (
    <DIV>
      <Drawer
        placement="left"
        destroyOnClose
        onClose={onClose}
        width={250}
        bodyStyle={{ padding: '12px 10px' }}
        visible={visible}
        closable={false}
      >
        <AccountListSideBar />
      </Drawer>
      <SectionWrapper className="relative h-20 w-full bg-white500" as="div">
        <div className="flex flex-row justify-between w-full h-20 px-4 space-x-6">
          <div style={{ maxWidth: 250 }} className="flex items-center justify-between">
            <div style={{ width: 250 }} className="cursor-pointer justify-between flex space-x-4 items-center mx-4">
              <a href={ctx.apply('routeStore.toUrl', 'toolAccountDetail')}>
                <div className="flex space-x-4 items-center">
                  <div className="flex items-center" style={{ width: 32, height: 32 }}>
                    <Image
                      src={
                        _.get(route.getParams(), 'account_profile.avatar_url') ||
                        'https://images.ctfassets.net/4cwknz5arvmg/353lGx5KBf0xu3V8AD6VYT/8556f0a211305bf23312f1b0543fd021/Logo_Unitz_OK_2.png?w=1100&q=50'
                      }
                      size="cover"
                      title="logo"
                      alt="logo_account"
                      preview={false}
                    />
                  </div>
                  {renderPropsComposer(
                    {
                      matcher: (props) =>
                        _.get(props, 'location.pathname') ===
                          `${lang}${_.get(props.getPageContext(), 'params.slug')}/create` &&
                        !!_.get(props.getPageContext(), 'params.accountId'),

                      render: () => (
                        <div className="text-main font-semibold text-sm">
                          {ctx.apply('i18n.t', 'ZoomToolOrganization.create')}
                        </div>
                      ),
                    },
                    {
                      matcher: (props) =>
                        _.get(props, 'location.pathname') === `${lang}accounts/me/create` &&
                        !_.get(props.getPageContext(), 'params.accountId'),

                      render: () => (
                        <div className="text-main font-semibold text-sm">
                          {ctx.apply('i18n.t', 'ZoomToolOrganization.create')}
                        </div>
                      ),
                    },
                    {
                      matcher: (props) => !_.get(props.getPageContext(), 'params.accountId'),
                      render: () => <div className="text-main font-semibold text-sm">Unitz</div>,
                    },
                    {
                      matcher: (props) => !!_.get(props.getPageContext(), 'params.accountId'),
                      render: (props) => (
                        <div className="flex flex-col">
                          <div className="text-main font-semibold text-sm">Unitz</div>
                          <div className="text-main font-semibold text-sm">
                            {_.get(props, 'params.account_profile.display_name', '')}
                          </div>
                        </div>
                      ),
                    },
                    () => null
                  )(route)}
                </div>
              </a>
              <div onClick={showDrawer}>
                {gstyles.icons({ name: 'list-bullet', size: 24, fill: gstyles.colors.main })}
              </div>
            </div>
          </div>
          <div className="flex items-center flex-row items-center">
            <div className="menu-account-wrapper flex items-center space-x-6">
              <NavbarNotification />
              <NavbarMenuProfile />
              <NavbarAuthSection />
            </div>
            <LangSwitch />
          </div>
        </div>
      </SectionWrapper>
    </DIV>
  );
};

export default DesktopNavbar;
