import React from 'react';

import { ctx } from '@vl/redata';
import DIV from '@vl/redata/DIV.macro';
import AccountListSideBar from '@uz/unitz-components-web/AccountListSideBar';
import { renderPropsComposer } from '@vl/mod-utils/PropsComposer';
import SectionWrapper from '@uz/unitz-ctf-theme/components/SectionWrapper';
import gstyles from '@vl/gstyles';
import LayoutNavMenu from '@uz/unitz-layout-web/LayoutNavMenu';
import LangSwitchTransparent from '@uz/unitz-ctf-theme/layouts/LangSwitchTransparentUS';
import { getCurrentLocaleConfig } from '@uz/mod-translations/utils-biz';
import useRoute from '@vl/hooks/useGbRouteDe';
import { Drawer, Modal } from 'antd';
import Image from '@uz/unitz-components-web/Image';
import NavbarMenuProfile from '@uz/unitz-tool-components/NavbarMenuProfile';
import NavbarAuthSection from '@uz/unitz-tool-components/NavbarAuthSection';
import _ from 'lodash';

const TabletNavbar = () => {
  const [visible, setVisible] = React.useState(false);
  const [modalVisible, $modalVisible] = React.useState(false);
  const showDrawer = () => {
    setVisible(true);
  };
  const onClose = () => {
    setVisible(false);
  };

  const openModal = () => {
    $modalVisible(true);
  };

  const closeModal = () => {
    $modalVisible(false);
  };

  const route = useRoute();
  const currentLocale = getCurrentLocaleConfig(route);
  const [lang, $lang] = React.useState(false);

  const flags = {
    vi: 'vietnam-flag',
    en: 'uk-flag',
    de: 'german-flag',
  };

  const MenuModal = () => {
    return (
      <Modal
        visible={modalVisible}
        onCancel={closeModal}
        onClose={closeModal}
        wrapClassName={'my-modal'}
        className="rounded-lg overflow-hidden shadow-lg"
        maskStyle={{ background: gstyles.colors.black300 }}
        destroyOnClose
        centered
        width={688}
        closable={false}
        footer={false}
        afterClose={closeModal}
      >
        <DIV>
          <LayoutNavMenu>
            <LayoutNavMenu.POS name="nav-lang">
              <div className="flex justify-between items-center p-4">
                <div className="flex items-center space-x-2" onClick={() => $lang(!lang)}>
                  <span>
                    {gstyles.icons({
                      name: _.get(flags, currentLocale.get('lang'), ''),
                      size: 24,
                    })}
                  </span>
                  <span className="text-main">
                    {ctx.apply('i18n.t', `SettingLanguage.${currentLocale.get('lang')}`)}
                  </span>
                  <span>
                    {gstyles.icons({
                      name: 'arrow-down3',
                      size: 24,
                    })}
                  </span>
                </div>
              </div>
              {!!lang && <LangSwitchTransparent />}
            </LayoutNavMenu.POS>

            <LayoutNavMenu.POS name="nav-profile">
              <NavbarMenuProfile />
              <NavbarAuthSection />
            </LayoutNavMenu.POS>

            <LayoutNavMenu.POS name="nav-ctf-menu">
              <div className="flex flex-col items-start flex-1">{ctx.apply('ctf.renderSectionChild', 1)}</div>
            </LayoutNavMenu.POS>
          </LayoutNavMenu>
        </DIV>
      </Modal>
    );
  };

  return (
    <DIV>
      <Drawer
        placement="left"
        bodyStyle={{ padding: 10 }}
        closable={false}
        onClose={onClose}
        width={'50%'}
        visible={visible}
      >
        <div className="w-full">
          <AccountListSideBar />
        </div>
      </Drawer>
      <SectionWrapper className="h-16 relative bg-white500 shadow-lg" as="div">
        <MenuModal />
        <div className="flex flex-row h-16 px-4 app-row justify-between w-full">
          <div style={{ maxWidth: 250 }} className="flex items-center justify-between">
            <div
              style={{ width: 250 }}
              onClick={showDrawer}
              className="cursor-pointer justify-between flex space-x-4 items-center"
            >
              <div className="flex space-x-4 items-center">
                <div className="flex items-center" style={{ width: 32, height: 32 }}>
                  <Image
                    src={
                      _.get(route, 'params.account_profile.avatar_url') ||
                      'https://images.ctfassets.net/4cwknz5arvmg/353lGx5KBf0xu3V8AD6VYT/8556f0a211305bf23312f1b0543fd021/Logo_Unitz_OK_2.png?w=1100&q=50'
                    }
                    size="cover"
                    title="logo"
                    alt="logo_account"
                    preview={false}
                  />
                </div>
                {renderPropsComposer(
                  {
                    matcher: (props) =>
                      _.get(props, 'location.pathname') ===
                        `${lang}${_.get(props.getPageContext(), 'params.slug')}/create` &&
                      !!_.get(props.getPageContext(), 'params.accountId'),

                    render: () => (
                      <div className="text-main font-semibold text-sm">
                        {ctx.apply('i18n.t', 'ZoomToolOrganization.create')}
                      </div>
                    ),
                  },
                  {
                    matcher: (props) =>
                      _.get(props, 'location.pathname') === `${lang}accounts/me/create` &&
                      !_.get(props.getPageContext(), 'params.accountId'),

                    render: () => (
                      <div className="text-main font-semibold text-sm">
                        {ctx.apply('i18n.t', 'ZoomToolOrganization.create')}
                      </div>
                    ),
                  },
                  {
                    matcher: (props) => !_.get(props.getPageContext(), 'params.accountId'),
                    render: () => <div className="text-main font-semibold text-sm">Unitz</div>,
                  },
                  {
                    matcher: (props) => !!_.get(props.getPageContext(), 'params.accountId'),
                    render: (props) => (
                      <div className="flex flex-col">
                        <div className="text-main font-semibold text-sm">Unitz</div>
                        <div className="text-main font-semibold text-sm">
                          {_.get(props, 'params.account_profile.display_name', '')}
                        </div>
                      </div>
                    ),
                  },
                  () => null
                )(route)}
              </div>
            </div>
          </div>
          <button className="focus:outline-none cursor-pointer" onClick={openModal}>
            {gstyles.icons({ name: 'menu', size: 24, fill: gstyles.colors.main })}
          </button>
        </div>
      </SectionWrapper>
    </DIV>
  );
};

export default TabletNavbar;
