import React from 'react';
import { ctx } from '@vl/redata';
import DIV from '@vl/redata/DIV.macro';
import _ from 'lodash';
import Link from '@uz/unitz-components-web/Link';
import { Row, Col } from 'antd';
import styled from 'styled-components';

const CustomRow = styled(Row)`
  min-width: 300px;
`;

function AuthSectionLayout() {
  return (
    <DIV>
      {!ctx.apply('currentUserModel.getUserId') && (
        <CustomRow align="middle" gutter={12} className="px-0 lg:px-3 py-3 lg:py-0 auth-group py-0" id="auth-group">
          <Col xs={12} md={12} lg={12} xl={12}>
            <Link
              className="border rounded-lg bg-brandb500 border-transparent xl:bg-transparent w-full xl:w-32 h-8 xl:h-10 flex text-white500 justify-center items-center text-base font-semibold"
              to={'#login'}
              onClick={() => ctx.apply('authModel.login')}
            >
              {ctx.apply('i18n.t', 'Auth.login')}
            </Link>
          </Col>
          <Col xs={12} md={12} lg={12} xl={12}>
            <Link
              className="bg-brandb500 rounded-lg w-full xl:w-32 h-8 xl:h-10 flex justify-center items-center text-white500 text-base font-semibold"
              to={'#register'}
              onClick={() => ctx.apply('authModel.register')}
            >
              {ctx.apply('i18n.t', 'Auth.register')}
            </Link>
          </Col>
        </CustomRow>
      )}
    </DIV>
  );
}

export default AuthSectionLayout;
